export const NODE_ENV = process.env.NODE_ENV
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV

export const DOCUMENT_TITLE = 'HK IVA'
export const DOCUMENT_DESCRIPTION = 'HK IVA'
export const APP_NAME = 'hkiva'
export const APP_CACHE_KEY = 'hkiva'
export const DEFAULT_PHONE_COUNTRY_CODE = '852'
export const DEFAULT_SYSTEM_UTC_OFFSET = '+08:00'

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION
export const BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST
export const CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL

export const BASE_CDN = process.env.NEXT_PUBLIC_CDN_HOST
