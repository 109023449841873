import { createTheme, darken } from '@mui/material/styles'

import generalStyles from './general.styles'
import overridesStyles from './overrides.styles'
import customStyles from './custom.styles'

import type { TypographyOptions } from '@mui/material/styles/createTypography'
import type { Palette } from '@mui/material/styles'

const muiTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1268,
			xl: 1468,
		},
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
	},
})

const fontFamilySans = [`var(--noto-sans-hk-font)`, 'sans-serif'].join(', ')
const fontFamilySerif = [`var(--noto-serif-hk-font)`, 'sans-serif'].join(', ')

const typography: TypographyOptions = {
	fontFamily: fontFamilySans,
	fontWeightBlack: 900,
	fontWeightExtraBold: 800,
	fontWeightBold: 700,
	fontWeightSemiBold: 600,
	fontWeightMedium: 500,
	fontWeightRegular: 400,
	fontWeightLight: 300,
	htmlFontSize: 16,
	//
	body1: undefined,
	body2: undefined,
	caption: undefined,
	h1: undefined,
	h2: undefined,
	h3: undefined,
	h4: undefined,
	h5: undefined,
	h6: undefined,
	overline: undefined,
	subtitle1: undefined,
	subtitle2: undefined,
	/**
	 * Project variant
	 */
	head: {
		fontFamily: fontFamilySerif,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(56),
		fontStyle: 'normal',
		lineHeight: 'normal',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(32),
		},
	},

	title: {
		fontFamily: fontFamilySerif,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(38),
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(24),
		},
	},

	section: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(30),
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(18),
		},
	},

	regular22: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontStyle: 'normal',
		fontSize: muiTheme.typography.pxToRem(22),
		lineHeight: 1.5,
		letterSpacing: 'normal',
		wordBreak: 'break-word',
		textTransform: 'initial',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(16),
		},
	},

	regular: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontStyle: 'normal',
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: 1.5,
		letterSpacing: 'normal',
		wordBreak: 'break-word',
		textTransform: 'initial',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(14),
		},
	},

	button: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: 1.5,
		letterSpacing: 'normal',
		wordBreak: 'break-word',
		textTransform: 'initial',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(14),
		},
	},
	//
	bodyReg14: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(14),
		lineHeight: '20px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodyMed14: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(14),
		lineHeight: '20px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodySemi14: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(14),
		lineHeight: '20px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodyBold14: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(14),
		lineHeight: '20px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},

	bodyReg16: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',

		[muiTheme.breakpoints.down('md')]: {
			fontSize: muiTheme.typography.pxToRem(14),
		},
	},
	bodyMed16: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodySemi16: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodyBold16: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(16),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},

	bodyReg18: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(18),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodyMed18: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(18),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodySemi18: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(18),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	bodyBold18: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(18),
		lineHeight: '24px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},

	titleReg24: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(24),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	titleMed24: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(24),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	titleSemi24: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(24),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	titleBold24: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(24),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},

	headReg32: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(32),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headMed32: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(32),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headSemi32: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(32),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headBold32: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(32),
		lineHeight: 'normal',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},

	headReg40: {
		fontFamily: fontFamilySans,
		fontWeight: 400,
		fontSize: muiTheme.typography.pxToRem(40),
		lineHeight: '72px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headMed40: {
		fontFamily: fontFamilySans,
		fontWeight: 500,
		fontSize: muiTheme.typography.pxToRem(40),
		lineHeight: '72px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headSemi40: {
		fontFamily: fontFamilySans,
		fontWeight: 600,
		fontSize: muiTheme.typography.pxToRem(40),
		lineHeight: '72px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
	headBold40: {
		fontFamily: fontFamilySans,
		fontWeight: 700,
		fontSize: muiTheme.typography.pxToRem(40),
		lineHeight: '72px',
		letterSpacing: 'normal',
		wordBreak: 'break-word',
	},
}

const appColors = {
	neutral: {
		50: '#F5F5F5',
		100: '#F6F6F6',
		200: '#8F8F8F',
		300: '#949494',
		400: '#E0E0E0',
		500: '#4A4A4A',
		600: '#313131',
		700: '#06080E',
	} as Palette['neutral'],
	red: {
		100: '#EBD4D3',
		200: '#D8A9A7',
		300: '#C47D7C',
		400: '#B15250',
		500: '##9D2724',
		600: '##7E1F1D',
		700: '#5E1716',
		800: '#3F100E',
		900: '#1F0807',
	} as Palette['red'],
}

const defaultTheme = createTheme({
	...muiTheme,
	palette: {
		primary: {
			main: '#AF2B29',
			contrastText: muiTheme.palette.common.white,
		},
		secondary: {
			light: appColors.neutral[300],
			main: appColors.neutral[500],
			dark: appColors.neutral[700],
			contrastText: muiTheme.palette.common.white,
		},
		success: {
			main: '#00814A',
			contrastText: muiTheme.palette.common.white,
		},
		error: {
			main: '#FF5E54',
			contrastText: muiTheme.palette.common.white,
		},
		neutral: { ...appColors.neutral },
		red: { ...appColors.red },
		socialMedia: {
			facebook: '#334C8C',
			whatsapp: '#2AA81A',
			instagram:
				'linear-gradient(0deg, #E09B3D 0.46%, #C74C4D 22.72%, #C21975 60.76%, #7024C4 93.22%)',
			darkInstagram: `linear-gradient(0deg, ${darken(
				'#E09B3D',
				muiTheme.palette.action.hoverOpacity,
			)} 0.46%, ${darken('#C74C4D', muiTheme.palette.action.hoverOpacity)} 22.72%, ${darken(
				'#C21975',
				muiTheme.palette.action.hoverOpacity,
			)} 60.76%, ${darken('#7024C4', muiTheme.palette.action.hoverOpacity)} 93.22%)`,
			youtube: '#F20000',
		},
		info: {
			main: '#0085FF',
			contrastText: muiTheme.palette.common.white,
		},
		background: {
			default: muiTheme.palette.common.white,
		},
		divider: appColors.neutral[200],
		text: {
			primary: appColors.neutral[700],
			secondary: appColors.neutral[300],
			disabled: appColors.neutral[200],
		},
		action: {
			tonalOpacity: 0.2,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: (themeParams: any) => `
				${generalStyles(themeParams)}
				${overridesStyles(themeParams)}
        		${customStyles(themeParams)}
			`,
		},
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: 1350,
				},
			},
		},
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave',
			},
		},
		MuiTypography: {
			defaultProps: {
				variant: 'regular',
				variantMapping: {
					head: 'h2',
					title: 'h4',
					regular: 'p',
					regular22: 'p',

					bodyReg14: 'p',
					bodyMed14: 'p',
					bodySemi14: 'p',
					bodyBold14: 'p',

					bodyReg16: 'p',
					bodyMed16: 'p',
					bodySemi16: 'p',
					bodyBold16: 'p',

					bodyReg18: 'p',
					bodyMed18: 'p',
					bodySemi18: 'p',
					bodyBold18: 'p',

					titleReg24: 'h5',
					titleMed24: 'h5',
					titleSemi24: 'h5',
					titleBold24: 'h5',

					headReg32: 'h4',
					headMed32: 'h4',
					headSemi32: 'h4',
					headBold32: 'h4',

					headReg40: 'h1',
					headMed40: 'h1',
					headSemi40: 'h1',
					headBold40: 'h1',
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'hover',
				color: appColors.neutral[700],
			},
		},
	},
	shape: {
		borderRadius: 8,
	},
	typography: { ...typography },
	zIndex: {
		backdrop: 1350,
		fab: 1050,
		speedDial: 1050,
		appBar: 1100,
		drawer: 1200,
		modal: 1300,
		snackbar: 1400,
		tooltip: 1500,
	},
	app: {
		shadows: {
			menu: '0px 8px 20px 0px #00000026',
			paper: '16px 16px 8px 0px #00000014',
		},
		sizes: {
			large: 50,
			medium: 44,
			small: 38,
		},
		spacings: {
			gutters: 24,
			guttersSmall: 16,
		},
		utils: {
			remToPx: (rem) =>
				(parseFloat(rem) * typography.htmlFontSize!) / (typography.fontSize! / 14),
		},
	},
})

export default defaultTheme
