import { AuthActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  SignOutSucceededAction,
  CheckAuthRequestedAction,
  CheckAuthFailedAction,
  CheckAuthSucceededAction,
  SignInSucceededAction,
  // Saga
  SignOutSagaAction,
  SignOutOnExpiredTokenSagaAction,
  SignInSagaAction,
  CheckAuthSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AuthActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AuthActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AuthActionTypes.FETCH_FAILED,
  payload,
});

export const checkAuthRequested = (): CheckAuthRequestedAction => ({
  type: AuthActionTypes.CHECK_AUTH_REQUESTED,
});

export const checkAuthSucceeded = (
  payload: CheckAuthSucceededAction["payload"]
): CheckAuthSucceededAction => ({
  type: AuthActionTypes.CHECK_AUTH_SUCCEEDED,
  payload,
});

export const checkAuthFailed = (
  payload: CheckAuthFailedAction["payload"]
): CheckAuthFailedAction => ({
  type: AuthActionTypes.CHECK_AUTH_FAILED,
  payload,
});

export const signOutSucceeded = (
  payload?: SignOutSucceededAction["payload"]
): SignOutSucceededAction => ({
  type: AuthActionTypes.SIGN_OUT_SUCCEEDED,
  payload,
});

export const signInSucceeded = (
  payload: SignInSucceededAction["payload"]
): SignInSucceededAction => ({
  type: AuthActionTypes.SIGN_IN_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const signInSaga = (
  payload: SignInSagaAction["payload"],
  meta?: SignInSagaAction["meta"]
): SignInSagaAction => ({
  type: AuthActionTypes.SIGN_IN_SAGA,
  payload,
  meta,
});

export const checkAuthSaga = (): CheckAuthSagaAction => ({
  type: AuthActionTypes.CHECK_AUTH_SAGA,
});

export const signOutSaga = (
  payload?: SignOutSagaAction["payload"],
  meta?: SignOutSagaAction["meta"]
): SignOutSagaAction => ({
  type: AuthActionTypes.SIGN_OUT_SAGA,
  payload,
  meta,
});

export const signOutOnExpiredTokenSaga =
  (): SignOutOnExpiredTokenSagaAction => ({
    type: AuthActionTypes.SIGN_OUT_ON_EXPIRED_TOKEN_SAGA,
  });
