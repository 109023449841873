import { enUS, zhHK } from '@mui/material/locale'

import { notoSansFonts, notoSerifFonts } from '@/utils/fonts'
// process.env.NODE_ENV === 'development' ? 'en-US' :
export const DEFAULT_LOCALE = 'zh-hk'

export const localeToConfigMap = {
	'en-US': {
		label: 'English',
		themeLocale: enUS,
		font: notoSerifFonts.notoSerifHK,
	},
	'zh-HK': {
		label: '繁體中文',
		themeLocale: zhHK,
		font: notoSansFonts.notoSansHK,
	},
} as Record<
	string,
	{
		label: string
		themeLocale: typeof enUS
		font: typeof notoSerifFonts.notoSerif
	}
>

export const localeConfigs = Object.entries(localeToConfigMap).map(([locale, config]) => ({
	locale,
	...config,
}))

export const locales = Object.entries(localeToConfigMap).map(([locale]) => locale)
