import { commonAxios } from '@/libs'

import type { ContactFormPayload, Service } from './contact.api.types'
import type { AxiosResponseData } from '@/libs/axios'

const commonApi = {
	fetchServices: () => {
		return commonAxios.get<AxiosResponseData<Service[]>>('/Registers/getServiceList')
	},
	submitConsultationInfo: (payload: ContactFormPayload) => {
		return commonAxios.post<AxiosResponseData>('Registers/addRegister', payload.params)
	},
}

export default commonApi
