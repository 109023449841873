import { commonAxios } from '@/libs'

import type { AxiosResponseData } from '@/libs/axios'
import { Social } from './social.api.types'

const commonApi = {
	fetchSocial: () => {
		return commonAxios.get<AxiosResponseData<Social[]>>('/Socials/getList')
	},
}

export default commonApi
