module.exports = {
  i18n: {
    locales: ["en-US", "zh-HK"],
    defaultLocale: process.env.NODE_ENV === "development" ? "en-US" : "zh-HK",
  },
  fallbackLng: {
    default: ["en-us"],
    "en-US": ["en-us"],
    "zh-HK": ["zh-hk"],
  },
  localePath:
    typeof window === "undefined"
      ? require("path").resolve("./public/locales")
      : "/locales",
  reloadOnPrerender: process.env.NODE_ENV === "development",
};
