import type {
  SignInPayload,
  SignOutPayload,
  FetchAuthUserResponseData,
} from "@/utils/apis/auth";

export enum AuthActionTypes {
  FETCH_REQUESTED = "@@auth/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@auth/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@auth/FETCH_FAILED",

  SIGN_IN_SUCCEEDED = "@@auth/SIGN_IN_SUCCEEDED",
  SIGN_OUT_SUCCEEDED = "@@auth/SIGN_OUT_SUCCEEDED",

  CHECK_AUTH_REQUESTED = "@@auth/CHECK_AUTH_REQUESTED",
  CHECK_AUTH_SUCCEEDED = "@@auth/CHECK_AUTH_SUCCEEDED",
  CHECK_AUTH_FAILED = "@@auth/CHECK_AUTH_FAILED",

  // Saga
  CHECK_AUTH_SAGA = "@@auth/CHECK_AUTH_SAGA",
  SIGN_OUT_SAGA = "@@auth/SIGN_OUT_SAGA",
  SIGN_OUT_ON_EXPIRED_TOKEN_SAGA = "@@auth/SIGN_OUT_ON_EXPIRED_TOKEN_SAGA",
  SIGN_IN_SAGA = "@@auth/SIGN_IN_SAGA",
}

// State

export interface AuthState {
  authUser: FetchAuthUserResponseData["data"] | null;
  authUserChecking: boolean;
  authUserCheckedError: string;
  authUserLoading: boolean;
  authUserError: string;
  authUserExpiredToken: boolean;
}

// ---- Reducer Action ----

export type FetchScope = "authUser";

export type FetchRequestedAction = {
  type: AuthActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AuthActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AuthState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AuthActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type SignInSucceededAction = {
  type: AuthActionTypes.SIGN_IN_SUCCEEDED;
  payload: FetchAuthUserResponseData["data"];
};

export type CheckAuthRequestedAction = {
  type: AuthActionTypes.CHECK_AUTH_REQUESTED;
};

export type CheckAuthFailedAction = {
  type: AuthActionTypes.CHECK_AUTH_FAILED;
  payload: {
    message: string;
    isNetworkError?: boolean;
  };
};

export type CheckAuthSucceededAction = {
  type: AuthActionTypes.CHECK_AUTH_SUCCEEDED;
  payload: AuthState["authUser"] | null;
};

export type SignOutSucceededAction = {
  type: AuthActionTypes.SIGN_OUT_SUCCEEDED;
  payload?: {
    reason?: string;
  };
};

// ---- Saga Action ----

export type SignOutSagaAction = {
  type: AuthActionTypes.SIGN_OUT_SAGA;
  payload?: SignOutPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SignOutOnExpiredTokenSagaAction = {
  type: AuthActionTypes.SIGN_OUT_ON_EXPIRED_TOKEN_SAGA;
};

export type SignInSagaAction = {
  type: AuthActionTypes.SIGN_IN_SAGA;
  payload: SignInPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CheckAuthSagaAction = {
  type: AuthActionTypes.CHECK_AUTH_SAGA;
};

export type AuthAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | CheckAuthRequestedAction
  | CheckAuthSucceededAction
  | CheckAuthFailedAction
  | SignInSucceededAction
  | SignOutSucceededAction
  //
  | CheckAuthSagaAction
  | SignOutSagaAction
  | SignOutOnExpiredTokenSagaAction
  | SignInSagaAction;
