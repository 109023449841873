export const homeBanner = [
	{
		id: 1,
		image: '/images/pages/home/banner_1.png',
	},
	{
		id: 2,
		image: '/images/pages/home/home1.png',
	},
	{
		id: 3,
		image: '/images/pages/home/home2.png',
	},
] as const

export const homeWhyHiringUs = [
	{ keyTrans: 'homePage.whyHire1' },
	{ keyTrans: 'homePage.whyHire2' },
	{ keyTrans: 'homePage.whyHire3' },
	{ keyTrans: 'homePage.whyHire4' },
	{ keyTrans: 'homePage.whyHire5' },
] as const

export const tableData = {
	1: [
		{
			agency: '銀行 A',
			debt: '$96,000',
			minPaidMonthly: '$6,000',
			number: '84',
			interestExpenseMonthly: '$880',
			contributionAfterRelief: '$1,911',
			interestAfterReliefMonthly: '$768',
		},
		{
			agency: '銀行 B',
			debt: '$43,000',
			minPaidMonthly: '$2,150',
			number: '72',
			interestExpenseMonthly: '$1,290',
			contributionAfterRelief: '$1,672',
			interestAfterReliefMonthly: '$1,075',
		},
		{
			agency: '銀行 C',
			debt: '$118,000',
			minPaidMonthly: '$6,000',
			number: '72',
			interestExpenseMonthly: '$2,622',
			contributionAfterRelief: '$2,819',
			interestAfterReliefMonthly: '$1,180',
		},
		{
			agency: '財務公司 A',
			debt: '$22,160',
			minPaidMonthly: '$1,400',
			number: '84',
			interestExpenseMonthly: '$111',
			contributionAfterRelief: '$264',
			interestAfterReliefMonthly: '$0',
		},
		{
			agency: '財務公司 B',
			debt: '$70,177',
			minPaidMonthly: '$3,509',
			number: '72',
			interestExpenseMonthly: '$2,105',
			contributionAfterRelief: '$1,676',
			interestAfterReliefMonthly: '$702',
		},
		{
			agency: '總數',
			debt: '$349,337',
			minPaidMonthly: '$19,059',
			number: '',
			interestExpenseMonthly: '$7,008',
			contributionAfterRelief: '$8,342',
			interestAfterReliefMonthly: '$3,725',
		},
	],
	// 2
	2: [
		{
			agency: '銀行 A',
			debt: '$96,000',
			minPaidMonthly: '$6,000',
			number: '84',
			interestExpenseMonthly: '$880',
			contributionAfterRelief: '$1,911',
			interestAfterReliefMonthly: '$768',
		},
		{
			agency: '銀行 B',
			debt: '$43,000',
			minPaidMonthly: '$2,150',
			number: '72',
			interestExpenseMonthly: '$1,290',
			contributionAfterRelief: '$1,672',
			interestAfterReliefMonthly: '$1,075',
		},
		{
			agency: '銀行 C',
			debt: '$118,000',
			minPaidMonthly: '$6,000',
			number: '72',
			interestExpenseMonthly: '$2,622',
			contributionAfterRelief: '$2,819',
			interestAfterReliefMonthly: '$1,180',
		},
		{
			agency: '總數',
			debt: '$349,337',
			minPaidMonthly: '$19,059',
			number: '',
			interestExpenseMonthly: '$7,008',
			contributionAfterRelief: '$8,342',
			interestAfterReliefMonthly: '$3,725',
		},
	],
	// 3
	// 4
	4: [
		{
			originMortgage: '$1,404,954',
			otherDebtOwned: '$632,489',
			minPaidMonthly: '$24,381',
			loanAmountRemortgage: '$2,200,000',
			paymentAmountRemortgage: '$9,879',
			period: '360個月',
		},
	],
}
