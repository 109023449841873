import { vtRemingtonPortableFonts } from '@/utils/fonts'
import { colors as muiColorMap } from '@mui/material'

export type Options = {
	width?: number
	minWidth?: number
	height?: number
	fontSize?: number
}

const getRandomItem = <T>(arr: T[]): T => {
	const randomIndex = Math.floor(Math.random() * arr.length)
	return arr[randomIndex]
}

const generateRandomChar = (): string => {
	const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	return chars.charAt(Math.floor(Math.random() * chars.length))
}

const getAvailableColors = (): string[] => {
	return Object.entries(muiColorMap)
		.flatMap(([_, colorItem]) =>
			Object.entries(colorItem)
				.filter(([colorWeight]) => colorWeight === '900')
				.map(([_, color]) => color),
		)
		.filter(Boolean) as string[]
}

const strokeRandomLine = (
	ctx: CanvasRenderingContext2D,
	width: number,
	height: number,
	colors: string[],
) => {
	const lineStart = { x: Math.random() * width, y: Math.random() * height }
	const lineEnd = { x: Math.random() * width, y: Math.random() * height }

	ctx.beginPath()
	ctx.strokeStyle = getRandomItem(colors)
	ctx.moveTo(lineStart.x, lineStart.y)
	ctx.lineTo(lineEnd.x, lineEnd.y)
	ctx.stroke()
}

export const generateCanvasUrl = (textLength: number, options?: Options) => {
	const defaultFontSize = options?.fontSize || 20
	const padding = 10

	let width = options?.width ?? padding * 2 + textLength * defaultFontSize
	const height = options?.height ?? 50
	width = Math.max(width, options?.minWidth ?? 0)

	const Canvas = document.createElement('canvas')
	Canvas.width = width
	Canvas.height = height
	const ctx = Canvas.getContext('2d')!
	const fontFamily = vtRemingtonPortableFonts.VTRemingtonPortable.style.fontFamily

	const colors = getAvailableColors()

	const randomText = Array.from({ length: textLength }, generateRandomChar).join('')

	ctx.font = `${options?.fontSize ?? defaultFontSize}px ${fontFamily}`
	ctx.textBaseline = 'middle'

	randomText.split('').forEach((char, index) => {
		const color = getRandomItem(colors)
		const xPos = padding + index * (options?.fontSize ?? defaultFontSize)
		const yPos = height / 2 + (Math.random() - 0.5) * 10

		ctx.save()
		ctx.fillStyle = color
		ctx.translate(xPos, yPos)
		ctx.rotate((Math.random() - 0.5) * 0.1)
		ctx.fillText(char, 0, 0)
		ctx.restore()
	})

	let linesToDraw = Math.max(5, textLength / 2)
	while (linesToDraw--) {
		strokeRandomLine(ctx, width, height, colors)
	}

	const url = Canvas.toDataURL()
	Canvas.remove()

	return { url, code: randomText }
}
