import { Theme } from '@mui/material/styles'

const styleOverrides = (_: Theme) => {
	return {
		html: {
			scrollBehavior: 'smooth !important',
		},
	}
}

export default styleOverrides
