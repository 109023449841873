import { commonAxios } from "@/libs";

import type {
  SubmitConsultationInfoPayload,
  FetchCaptchaCodePayload,
  FetchCaptchaCodeResponseData,
} from "./common.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const commonApi = {
  fetchCaptchaCode: (payload: FetchCaptchaCodePayload) => {
    return commonAxios.post<AxiosResponseData<FetchCaptchaCodeResponseData>>(
      "cust_relation_management/request_informations/get_captcha_code.json",
      undefined,
      {
        cancelToken: payload?.cancelToken,
        withCredentials: true,
      }
    );
  },
  submitConsultationInfo: (payload: SubmitConsultationInfoPayload) => {
    return commonAxios.post<AxiosResponseData>(
      "cust_relation_management/subscribers/create_subcribers.json",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default commonApi;
