import type {
  FetchCaptchaCodePayload,
  SubmitConsultationInfoPayload,
} from "@/utils/apis/common";

export enum CommonActionTypes {
  FETCH_REQUESTED = "@@common/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@common/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@common/FETCH_FAILED",

  // Saga
  FETCH_CAPTCHA_CODE_SAGA = "@@common/FETCH_CAPTCHA_CODE_SAGA",
  SUBMIT_CONSULTATION_INFO_SAGA = "@@common/SUBMIT_CONSULTATION_INFO_SAGA",
}

// State

export interface CommonState {
  captchaCode: string;
  captchaCodeLoading: boolean;
  captchaCodeError: string;
}

// ---- Reducer Action ----

export type FetchScope = "captchaCode";

export type FetchRequestedAction = {
  type: CommonActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CommonActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CommonState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CommonActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type SubmitConsultationInfoSagaAction = {
  type: CommonActionTypes.SUBMIT_CONSULTATION_INFO_SAGA;
  payload: SubmitConsultationInfoPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchCaptchaCodeSagaAction = {
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA;
  payload?: FetchCaptchaCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CommonAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchCaptchaCodeSagaAction
  | SubmitConsultationInfoSagaAction;
