import { CommonActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchCaptchaCodeSagaAction,
  SubmitConsultationInfoSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CommonActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CommonActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CommonActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchCaptchaSaga = (
  payload?: FetchCaptchaCodeSagaAction["payload"],
  meta?: FetchCaptchaCodeSagaAction["meta"]
): FetchCaptchaCodeSagaAction => ({
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA,
  payload,
  meta,
});

export const submitConsultationInfoSaga = (
  payload: SubmitConsultationInfoSagaAction["payload"],
  meta?: SubmitConsultationInfoSagaAction["meta"]
): SubmitConsultationInfoSagaAction => ({
  type: CommonActionTypes.SUBMIT_CONSULTATION_INFO_SAGA,
  payload,
  meta,
});
