import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { commonApi } from "@/utils/apis";

import { CommonActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchCaptchaCodeSagaAction,
  FetchScope,
  SubmitConsultationInfoSagaAction,
} from "./types";

function* fetchCaptchaCodeSaga(action: FetchCaptchaCodeSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};
  const scope = "captchaCode" as FetchScope;
  yield put(
    fetchRequested({
      scope,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.fetchCaptchaCode>> = yield call(
      commonApi.fetchCaptchaCode,
      {
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.params?.capcha_number ?? "",
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* submitConsultationSaga(action: SubmitConsultationInfoSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof commonApi.submitConsultationInfo>> =
      yield call(commonApi.submitConsultationInfo, {
        params,
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* commonSaga() {
  yield all([
    takeEvery(CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA, fetchCaptchaCodeSaga),
    takeLatest(
      CommonActionTypes.SUBMIT_CONSULTATION_INFO_SAGA,
      submitConsultationSaga
    ),
  ]);
}

export default commonSaga;
