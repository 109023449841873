import { Theme } from '@mui/material/styles'

const styleOverrides = (theme: Theme) => {
	return `
    .html-scroll-smooth {
      scroll-behavior: smooth !important;
    }


    .strikethru {
       position: relative;
    }
    .strikethru:before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      right: 0;
      border-top: 1px solid;
      border-color: inherit;

      -webkit-transform:rotate(-5deg);
      -moz-transform:rotate(-5deg);
      -ms-transform:rotate(-5deg);
      -o-transform:rotate(-5deg);
      transform:rotate(-15deg);
    }

    .image-wrap {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;

      > div {
          position: unset !important;
      }

      .image {
          width: 100% !important;
          position: relative !important;
      }

      .image-contain {
          object-fit: contain;
          width: 100% !important;
          position: relative !important;
          height: unset !important;
      }

      .image-cover {
          object-fit: cover;
          width: 100% !important;
          position: relative !important;
      }
    }
    [${theme.breakpoints.down('lg')}] {
      .image {
        object-fit: cover;
      }
    }
	`
}

export default styleOverrides
