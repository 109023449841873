import { commonHelpers } from "@/utils/helpers";
import { AuthActionTypes } from "./types";

import type { AuthState, AuthAction } from "./types";

export const initialState: AuthState = {
  authUser: null,
  authUserCheckedError: "",
  authUserChecking: true,
  authUserError: "",
  authUserExpiredToken: false,
  authUserLoading: false,
};

const reducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionTypes.CHECK_AUTH_REQUESTED: {
      return {
        ...state,
        authUserExpiredToken: false,
        authUserChecking: true,
        authUserCheckedError: "",
      };
    }
    case AuthActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case AuthActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (
        isLoadMore &&
        Array.isArray(stateData) &&
        Array.isArray(newData) &&
        Array.isArray(data)
      ) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...(filteredData as any)] as any;
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case AuthActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }
    case AuthActionTypes.CHECK_AUTH_SUCCEEDED: {
      return {
        ...state,
        authUser: !!action.payload
          ? ({
              ...action.payload,
            } as AuthState["authUser"])
          : null,
        authUserChecking: false,
        authUserCheckedError: "",
      };
    }
    case AuthActionTypes.CHECK_AUTH_FAILED: {
      const { message, isNetworkError } = action.payload;

      return {
        ...state,
        authUser: isNetworkError ? state.authUser : null,
        authUserChecking: isNetworkError ? true : false,
        authUserCheckedError: message,
      };
    }

    case AuthActionTypes.SIGN_IN_SUCCEEDED: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case AuthActionTypes.SIGN_OUT_SUCCEEDED: {
      const { reason } = action?.payload || {};

      return {
        ...state,
        authUserExpiredToken: reason === "EXPIRED_TOKEN",
        authUser: null,
        authUserChecking: false,
        authUserCheckedError: "",
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
